import { container, defaultFont, paragraph } from "assets/jss/material-kit-react.js";

const privacyPolicyPageStyles = {
    main: {
        ...container,
        background: "#ffffff",
        position: "relative",
        zIndex: "3",
        paddingTop: "100px",
    },
    section: {
        ...container,
        background: "transparent",
        position: "relative",
        paddingLeft: "10%",
        paddingRight: "10%",
    },
    title: {
        ...defaultFont,
        fontSize: "30px",
        fontWeight:"700",
        marginBottom: "0px",
    },
    lastUpdated: {
        ...paragraph,
        fontSize: "10px",
        color: "#3C4858",
        marginTop: "0px",
        marginBottom: "30px",
    },
    info: {
        ...paragraph,
        fontSize: "15px",
        color: "#3C4858",
    },
    sectionTitle: {
        ...defaultFont,
        fontSize: "22px",
        fontWeight:"700",
        marginTop: "35px",
    },
    sectionSubtitle: {
        ...paragraph,
        fontSize: "15px",
        fontWeight:"700",
        marginBottom: "0px",
    },
    explination: {
        ...paragraph,
        fontSize: "15px",
        fontStyle: "italic",
    },
    topMargin: {
        marginTop: "10px",
    },
}

export default privacyPolicyPageStyles;