import { container, primaryColor, darkColor, defaultFont } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    ...defaultFont,
    color: darkColor,
    padding: "0.9375rem",
    fontSize: "15px",
    textTransform: "uppercase",
    textDecoration: "none",
    position: "relative",
    display: "block",
    "&:hover,&:focus": {
      color: "#FFFFFF",
    },
  },
  left: {
    float: "left!important",
    display: "block",
    textAlign: "left",
    paddingLeft: "20px",
    marginTop: "15px",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important",
    flexGrow: "1",
    marginTop: "-15px",
  },
  footer: {
    padding: "0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
    backgroundColor: primaryColor,
  },
  a: {
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container: {
    ...container,
    paddingBottom: "20px",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    textAlign: "left",

    "@media (min-width: 768px)": {
      display: "flex",
      justifyContent: "flex-end",
    }
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  logoContainer: {
    display: "block",
    width: "130px",
  },
  logoImg: {
    width: "116px",
    marginLeft: "20px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
  },
  contactInfo: {
    ...defaultFont,
    color: darkColor,
    fontSize: "12px",
  },
  socialIcon: {
    color: darkColor,
    fontSize: "25px",
    marginRight: "15px",

    "&:hover, &:focus": {
      color: "#ffffff",
    }
  },
  copyright: {
    ...defaultFont,
    textAlign: "right",
    paddingRight: "15px",
  }
};
export default footerStyle;
