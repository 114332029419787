import {
    defaultFont
  } from "assets/jss/material-kit-react.js";
  
  const editableInputStyle = {
    wrapper: {
        display: "inline-flex",
        alignItems: "center",
    },
    inputLable: {
        ...defaultFont,
        color: "#000000",
        fontWeight: "bold",
        marginTop: "10px",
        marginRight: "20px",
        flexBasis: "0",
        flexGrow: "1",
    },
    inputField: {
        ...defaultFont,
        flexBasis: "0",
        flexGrow: "4",
    },
  };
  
  export default editableInputStyle;
  