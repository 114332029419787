import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import styles from "assets/jss/material-kit-react/components/editableInputStyle.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function EditableInput(props) {
    const classes = useStyles();

    const {
        id,
        type,
        lableText,
        value,
        className,
        handleUpdate,
    } = props;

    const initialValue = value;

    const [inputValue, setInputValue] = React.useState(value);
    const [editInput, setEditInput] = React.useState();

    const handleClickInputEdit = () => {
        if (editInput && initialValue !== inputValue) {
            type === 'number' ? handleUpdate({ variables: { [id]: Number(inputValue) }}) : handleUpdate({ variables: { [id]: inputValue }});
        }
        setEditInput(!editInput);
    }

    const handleMouseDownInputEdit = event => {
        event.preventDefault();
    }

    const handleOnChange = event => {
        setInputValue(event.target.value);
    }

    return(
        <div className={classes.wrapper}>
            <InputLabel className={classes.inputLable}>{lableText}</InputLabel>
            <CustomInput
                id={id}
                formControlProps={{
                    fullWidth: true,
                    className: className || classes.inputField,
                }}
                inputProps={{
                    type,
                    disabled: !editInput,
                    disableUnderline: !editInput,
                    autoFocus: editInput,
                    value: inputValue,
                    onChange: handleOnChange,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={editInput ? `Save ${lableText}` : `Edit ${lableText}`}
                                onClick={handleClickInputEdit}
                                onMouseDown={handleMouseDownInputEdit}
                            >
                                {editInput ? <CheckOutlinedIcon /> : <EditOutlinedIcon />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};