import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import CircularProgress from '@material-ui/core/CircularProgress';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import "react-big-calendar/lib/css/react-big-calendar.css";

// import i18n (needs to be bundled ;)) 
import './i18n';

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import CompanyPage from"views/CompanyPage";
import PrivacyPolicyPage from"views/PrivacyPolicyPage";
import TermsAndConditionsPage from "views/TermsAndConditionsPage";
import CookiePolicyPage from "views/CookiePolicyPage";
import TestPage from "views/TestPage";

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: 'https://us-central1-fairhub-a2e60.cloudfunctions.net/api'
  //uri: 'http://localhost:5001/fairhub-a2e60/us-central1/api'
});

const client = new ApolloClient({
  cache,
  link
});

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAWx-IhkDxZJR6boYYRz6-9Vb48YxtgMKQ",
  authDomain: "fairhub-a2e60.firebaseapp.com",
  databaseURL: "https://fairhub-a2e60.firebaseio.com",
  projectId: "fairhub-a2e60",
  storageBucket: "fairhub-a2e60.appspot.com",
  messagingSenderId: "136847157472",
  appId: "1:136847157472:web:5ac84c7478146079"
};

firebase.initializeApp(firebaseConfig);

var hist = createBrowserHistory();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Suspense fallback={<CircularProgress style={{ position: "absolute", top: "calc(50% - 20px)", left:"calc(50% - 20px)" }}/>}>
      <Router history={hist}>
        <Switch>
          <Route path="/landing-page" component={LandingPage} />
          <Route path="/profile-page" component={ProfilePage} />
          <Route path="/login-page" component={LoginPage} />
          <Route path="/company" component={CompanyPage} />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route path="/terms-and-conditions" component={TermsAndConditionsPage} />
          <Route path="/cookie-policy" component={CookiePolicyPage} />
          <Route path="/test-page" component={TestPage} />
          <Route path="/" component={Components} />
        </Switch>
      </Router>
    </Suspense>
  </ApolloProvider>,
  document.getElementById("root")
);
