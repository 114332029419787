import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const HeaderLinks = props => {
  const { isApplicant, closeMenu } = props;
  const { t, i18n } = useTranslation();
  var selectedLang = i18n.language.substr(0, 2).toUpperCase();

  const changeLanguage = (lng) => {
    selectedLang = lng.toLowerCase();
    i18n.changeLanguage(selectedLang);
    closeMenu();
  }

  const classes = useStyles();
  const buttonClasses = { 
    "className": classes.navLink,
    "color": "transparent"
  };
  
  return (
    <List className={classes.list}>
      { isApplicant
        ?<ListItem className={classes.listItem}>
          <Link to={"/company"} className={classes.navLink}>
            {t('header-footer.companies')}
          </Link>
        </ListItem>
        :<ListItem className={classes.listItem}>
          <Link to={"/"}  className={classes.navLink}>
            {t('header-footer.jobs')}
          </Link>
        </ListItem>
      }
      <ListItem className={classes.listItem}>
        <CustomDropdown
          buttonText={selectedLang}
          dropdownList={[
            "DE",
            "EN",
            "ES",
            "IT",
            "FR"
          ]}
          onClick={changeLanguage}
          buttonProps={buttonClasses}
        >
        </CustomDropdown>
      </ListItem>
    </List>
  );
}

export default HeaderLinks;