import React, { useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/cookiePolicyPage.js";

const useStyles = makeStyles(styles);

const CookiePolicyPage = props => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { ...rest } = props;

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <>
            <Header
                fixed
                color="primary"
                {...rest}
            />

            <GridContainer className={classes.main}>
                <GridItem className={classes.section}>
                    <h1 className={classes.title}>
                        {t('cookies.title')}
                    </h1>
                    <p className={classNames(classes.info, classes.topMargin, classes.noBottomMargin)}>
                        {t('cookies.introduction')}
                    </p>

                    <h2 className={classes.sectionTitle}>
                        {t('cookies.section-1-title')}
                    </h2>
                    <p className={classes.info}>
                        {t('cookies.section-1-1')}
                    </p>
                    <p className={classes.info}>
                        {t('cookies.section-1-2')}
                    </p>
                    <p className={classes.info}>
                        {t('cookies.section-1-3')}
                    </p>

                    <h2 className={classes.sectionTitle}>
                        {t('cookies.section-2-title')}
                    </h2>
                    <p className={classes.info}>
                        {t('cookies.section-2-1')}
                    </p>

                    <h2 className={classes.sectionTitle}>
                        {t('cookies.section-3-title')}
                    </h2>
                    <p className={classes.info}>
                        {t('cookies.section-3-1')}
                    </p>
                </GridItem>
            </GridContainer>

            <Footer />
        </>
    );
};

export default CookiePolicyPage;