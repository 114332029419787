import React from "react";
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';

import { useTranslation } from 'react-i18next';

// @material-ui/icons
import styles from "assets/jss/material-kit-react/components/footerStyle.js";

import logoImage from "assets/img/logo.png";

const useStyles = makeStyles(styles);

const Footer = props => {

  const { isApplicant } = props;
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img src={logoImage} alt="Fairhub" className={classes.logoImg} />
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.left}>
            <p className={classes.contactInfo}>
              E-Mail: hello@fairhub.de
            </p>
            <p className={classes.contactInfo}>
              Tel: +49 171 9911143
            </p>
            <a href="https://www.facebook.com/Fairhub-109192467203388/" target="_blank" className={classes.socialIcon}>
              <i className={"fab fa-facebook"} />
            </a>
            <a href="https://www.instagram.com/fairhub/" target="_blank" className={classes.socialIcon}>
              <i className={"fab fa-instagram"} />
            </a>
          </div>
          <div className={classes.right}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                {isApplicant
                  ?<Link to="/company" className={classes.block}>
                    {t('header-footer.companies')}
                  </Link>
                  :<Link to="/" className={classes.block}>
                    {t('header-footer.jobs')}
                  </Link>
                }
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/terms-and-conditions" className={classes.block}>
                  {t('header-footer.legal-note')}
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/privacy-policy"
                  className={classes.block}
                >
                  {t('header-footer.data-note')}
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/cookie-policy"
                  className={classes.block}
                >
                  {t('header-footer.cookie-note')}
                </Link>
              </ListItem>
            </List>
            <p className={classes.copyright}>{'\u00A9'} Fairhub 2020 </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};

export default Footer;