import React, { useEffect, useRef, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import { useTranslation } from 'react-i18next';
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import sanitizeHtml from "sanitize-html";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, CircularProgress } from "@material-ui/core";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/components.js";

import diamondOneImage from "assets/img/diamond-1.png";
import diamondTwoImage from "assets/img/diamond-2-small.png";
import diamondsImage from "assets/img/diamonds.png";
import checkMark from "assets/img/check.png";
import phoneImage from "assets/img/phone-applicant.png";
import fillIcon from "assets/img/fill.png";
import uploadIcon from "assets/img/upload.png";
import handshakeIcon from "assets/img/handshake.png";
import coinsIcon from "assets/img/coins.png";

const useStyles = makeStyles(styles);

const ADD_MAIL = gql`
  mutation addUserMailingList($email:String!, $lang:String! $type:String!, $validationToken:String!){
    addUserMailingList(email:$email, lang:$lang, type:$type, validationToken: $validationToken)
}`;

const scrollToRef = ref => {
  if (window.innerWidth < 960) {
    window.scrollTo({
      left: 0,
      top: ref.current.getBoundingClientRect().top + window.scrollY - 80,
      behavior: 'smooth'
    });
  }
  else {
    window.scrollTo({
      left: 0,
      top: ref.current.getBoundingClientRect().top + window.scrollY - 480,
      behavior: 'smooth'
    });
  }
}

const scrollToView = ref => {
  ref.current.scrollIntoView({ behavior: 'smooth' });
}

const Components = props => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;

  const [inputValue, setInputValue] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const validEmail = (mail) => {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail));
  }

  const handleChange = event => {
    setInputValue(event.target.value);
    setDisplayError(!validEmail(event.target.value));
  }

  const [addMail] = useMutation(ADD_MAIL)

  const handleClick = event => {
    if (inputValue && validEmail(inputValue)) {
      window.grecaptcha.execute('6LfvvegUAAAAAHLInYv7LJU63Ypk0iFEXMvDaa0s', { action: 'registration' }).then(function (token) {
        addMail({ variables: { 'email': inputValue, 'lang': i18n.language, 'type': 'applicant', 'validationToken': token } });
      });
      setMessageSent(true);
    } else {
      setDisplayError(true);
    }
  };
  const moreInfoSection = useRef(null);
  const howItWorksSection = useRef(null);
  const signupSection = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        isApplicant={true}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "primary"
        }}
        {...rest}
      />
      <Parallax isApplicant={true}>
        <GridContainer justify="center" className={classNames(classes.conatinerFluid, classes.absolute)}>
          <GridItem xs={12} md={10} className={classes.parallaxContent}>
            <div className={classes.parallaxInfoContainer}>
              <p className={classes.subtitle}>
                {t('applicants.subtitle')}
              </p>
            </div>
            <Hidden smDown>
              <div className={classes.signupContainer}>
              {
                !messageSent
                  ?<>
                    <p className={classes.signupParagraph}>
                      {t('applicants.signup.text')}
                    </p>
                    <CustomInput
                      id="regular"
                      labelText={t('applicants.signup.email.placeholder')}
                      inputProps={{
                        value: inputValue,
                        onChange: handleChange
                      }}
                      success={validEmail(inputValue)}
                      error={displayError}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    <div className={classes.signupButtonsContainer}>
                      <Button
                        onClick={handleClick}
                        fullWidth={true}
                        className={classes.formButton}
                        color="black"
                      >
                        {t('applicants.signup.buttons.send')}
                      </Button>
                      <Button
                        fullWidth={true}
                        className={classes.formButton}
                        color="white"
                        onClick={() => scrollToRef(moreInfoSection)}
                      >
                        {t('applicants.signup.buttons.more-info')}
                      </Button>
                    </div>
                  </>
                  : <p className={classNames(classes.headline, classes.signupConfirmation)}>
                    {t('applicants.signup.sent')}
                  </p>
                  }
                </div>
            </Hidden>
            <div className={classes.decorationCircle}></div>
            <div className={classes.decorationLineHeader}></div>
          </GridItem>
        </GridContainer>
      </Parallax>

      <div className={classNames(classes.main, classes.whiteBackground)}>
        <Hidden mdUp>
          <GridContainer justify="center">
            <GridItem xs={12} md={10} className={classes.section}>
              <div className={classes.decorationLineSectionOne} />
              <div className={classes.decorationLineHorizontalOne} />
              <div className={classes.decorationCircleHorizontal} />
              <div>
              {!messageSent
                ?<>
                  <p className={classes.signupParagraph}>
                    {t('applicants.signup.text')}
                  </p>
                  <CustomInput
                    id="regular"
                    labelText={t('applicants.signup.email.placeholder')}
                    inputProps={{
                      value: inputValue,
                      onChange: handleChange
                    }}
                    success={validEmail(inputValue)}
                    error={displayError}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <div>
                    <Button
                      onClick={handleClick}
                      fullWidth={true}
                      className={classes.formButton}
                      color="black"
                    >
                      {t('applicants.signup.buttons.send')}
                    </Button>
                    <Button
                      fullWidth={true}
                      className={classes.formButton}
                      color="white"
                      onClick={() => scrollToRef(moreInfoSection)}
                    >
                      {t('applicants.signup.buttons.more-info')}
                    </Button>
                  </div>
                </>
                : <p className={classNames(classes.headline, classes.signupConfirmation)}>
                  {t('applicants.signup.sent')}
                </p>
                }
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>
        <GridContainer>
          <GridItem xs={12} md={10} className={classNames(classes.section, classes.noBottomPadding)}>
            <div className={classes.decorationLineSectionTwo} />
            <div className={classes.decorationLineHorizontalTwo} />
            <Hidden smDown>
              <div className={classes.decorationLineVertical} />
              <div className={classes.decorationCircleVertical} />
            </Hidden>
            <div className={classes.sectionOneHeading} ref={moreInfoSection}>
              <p className={classes.headline}>
                {t('applicants.advantages.title.money')}
              </p>
            </div>
            <div className={classes.diamondOneContainer}>
              <img src={diamondOneImage} className={classes.diamondImage} />
            </div>
            <div className={classes.sectionOneContainer}>
              <p className={classes.sectionOneParagraph}>
                {t('applicants.advantages.subtitle.when-where')}
              </p>
            </div>
            <div className={classes.checkMarksContainer}>
              <div className={classes.checkMarkContainer}>
                <img src={checkMark} alt="check mark" className={classes.checkMark} />
                <p className={classes.checkMarkText}>
                  {t('applicants.advantages.list.flexibility')}
                </p>
              </div>
              <div className={classes.checkMarkContainer}>
                <img src={checkMark} alt="check mark" className={classes.checkMark} />
                <p className={classes.checkMarkText}>
                  {t('applicants.advantages.list.time')}
                </p>
              </div>
              <div className={classes.checkMarkContainer}>
                <img src={checkMark} alt="check mark" className={classes.checkMark} />
                <p className={classes.checkMarkText}>
                  {t('applicants.advantages.list.income')}
                </p>
              </div>
              <div className={classes.checkMarkContainer}>
                <img src={checkMark} alt="check mark" className={classes.checkMark} />
                <p className={classes.checkMarkText}>
                  {t('applicants.advantages.list.fun')}
                </p>
              </div>
              <div className={classes.checkMarkContainer}>
                <img src={checkMark} alt="check mark" className={classes.checkMark} />
                <p className={classes.checkMarkText}>
                  {t('applicants.advantages.list.search-anywhere')}
                </p>
              </div>
              <div className={classes.checkMarkContainer}>
                <img src={checkMark} alt="check mark" className={classes.checkMark} />
                <p className={classes.checkMarkText}>
                  {t('applicants.advantages.list.costs')}
                </p>
              </div>
              <div className={classes.signupButtonsContainer}>
                <Button
                  fullWidth={true}
                  className={classes.formButton}
                  color="primary"
                  onClick={() => scrollToView(howItWorksSection)}
                >
                  {t('applicants.advantages.signup.buttons.how-it-works')}
                </Button>
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} md={10} className={classNames(classes.section, classes.noTopPadding)}>
            <div className={classes.decorationLineSectionThree} />
            <div className={classes.decorationCircleHorizontalTwo} />
            <div className={classes.decorationLineSectionFour} />
            <div className={classes.diamondsContainer}>
              <img src={diamondsImage} className={classes.diamondImage} />
            </div>
            <div className={classes.imageAndMottoContainer} ref={howItWorksSection}>
              <img src={phoneImage} className={classes.phoneImage} />
              <div className={classes.mottoContainer}>
                <div
                  className={classes.paragraph}
                  dangerouslySetInnerHTML={{__html: sanitizeHtml(t('applicants.how-it-works.concept'))}}
                />
                <Hidden smDown>
                  <div className={classes.signupButtonsContainer}>
                    <Button
                      color="primary"
                      onClick={() => scrollToView(signupSection)}
                    >
                      {t('applicants.advantages.signup.buttons.register')}
                    </Button>
                  </div>
                </Hidden>
              </div>
            </div>
            <div className={classes.iconsContainer}>
              <div className={classes.iconContainer}>
                <img src={fillIcon} alt="fill profile" className={classes.icon} />
                <p className={classes.iconText}>
                  {t('applicants.how-it-works.fill-profile')}
                </p>
              </div>
              <div className={classes.iconContainer}>
                <img src={uploadIcon} alt="upload profile" className={classes.icon} />
                <p className={classes.iconText}>
                  {t('applicants.how-it-works.upload-profile')}
                </p>
              </div>
              <div className={classes.iconContainer}>
                <img src={handshakeIcon} alt="strike deal" className={classes.icon} />
                <p className={classes.iconText}>
                  {t('applicants.how-it-works.strike-deal')}
                </p>
              </div>
              <div className={classes.iconContainer}>
                <img src={coinsIcon} alt="get paid" className={classes.icon} />
                <p className={classes.iconText}>
                  {t('applicants.how-it-works.get-paid')}
                </p>
              </div>
            </div>
            <Hidden mdUp>
              <div className={classes.signupButtonsContainer}>
                <Button
                  fullWidth={true}
                  color="primary"
                  onClick={() => scrollToView(signupSection)}
                >
                  {t('applicants.advantages.signup.buttons.register')}
                </Button>
              </div>
            </Hidden>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} md={10} className={classNames(classes.section, classes.noTopPadding)}>
            <div className={classes.decorationLineSectionFive} />
            <div className={classes.decorationLineHorizontalThree} />
            <div className={classes.decorationCircleHorizontalThree} />
            <div className={classes.diamondTwoContainer}>
              <img src={diamondTwoImage} className={classes.diamondImage} />
            </div>
            <div className={classes.lastSection} ref={signupSection}>
              {!messageSent
                ?<>
                <p className={classes.headline}>
                  {t('applicants.register-now')}
                </p>
                <p className={classNames(classes.paragraph, classes.noPadding)}>
                  {t('applicants.signup.text')}
                </p>
                <CustomInput
                  id="regular"
                  labelText={t('applicants.signup.email.placeholder')}
                  inputProps={{
                    value: inputValue,
                    onChange: handleChange
                  }}
                  success={validEmail(inputValue)}
                  error={displayError}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                <div className={classes.signupButtonsContainer}>
                  <Button onClick={handleClick} className={classes.signupButton} color="black">{t('applicants.signup.buttons.send')}</Button>
                </div>
              </>
              : <p className={classNames(classes.headline, classes.signupConfirmation)}>
                {t('applicants.signup.sent')}
              </p>
              }
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <Footer isApplicant={true} />
    </div>
  );
}

export default Components;
