import React, { useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

import { useTranslation } from 'react-i18next';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/privacyPolicyPage.js";

const useStyles = makeStyles(styles);

const PrivacyPolicyPage = props => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
      window.scrollTo(0,0);
  }, []);

  return (
    <>
        <Header fixed color="primary" {...rest} />

        <GridContainer className={classes.main}>
            <GridItem className={classes.section}>
                <h1 className={classes.title}>
                    {t('privacy.title')}
                </h1>

                <p className={classes.lastUpdated}>
                    {t('privacy.last-edited')}
                </p>
                <p className={classes.info}>
                    {t('privacy.intro-1')}
                </p>
                <p className={classes.info}>
                    {t('privacy.intro-2')}
                </p>
                <p className={classes.info}>
                    {t('privacy.intro-3')}
                </p>
                <p className={classes.info}>
                    {t('privacy.intro-4')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-1-title')}
                </h2>
                <p className={classes.info}>
                    {t('privacy.section-1-1')}
                </p>
                <address className={classes.info}>
                    {t('privacy.section-1-2-1')} <br />
                    {t('privacy.section-1-2-2')} <br />
                    {t('privacy.section-1-2-3')} <br />
                    {t('privacy.section-1-2-4')}
                </address>
                <p className={classNames(classes.info, classes.topMargin)}>
                    {t('privacy.section-1-3')}
                    <br />
                    {t('privacy.section-1-4')}
                </p>

                <p className={classes.info}>
                    {t('privacy.section-1-5')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-2-title')}
                </h2>
                <p className={classes.sectionSubtitle}>
                    {t('privacy.section-2-subtitle')}
                </p>
                <p className={classes.explination}>
                    {t('privacy.section-2-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-2-1')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-2-2')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-2-3')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-2-4')}
                </p>
                <p className={classes.sectionSubtitle}>
                    {t('privacy.section-2-5')}
                </p>
                <p className={classes.explination}>
                    {t('privacy.section-2-6')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-2-7')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-2-8')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-2-9')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-3-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-3-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-3-1')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-3-2')}
                </p>
                <ul>
                    <li className={classes.info}>
                        {t('privacy.section-3-3')}
                    </li>
                    <li className={classes.info}>
                        {t('privacy.section-3-4')}
                    </li>
                    <li className={classes.info}>
                        {t('privacy.section-3-5')}
                    </li>
                </ul>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-4-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-4-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-4-1')}
                </p>
                <ul>
                    <li className={classes.info}>
                        {t('privacy.section-4-2')}
                    </li>
                    <li className={classes.info}>
                        {t('privacy.section-4-3')}
                    </li>
                    <li className={classes.info}>
                        {t('privacy.section-4-4')}
                    </li>
                    <li className={classes.info}>
                        {t('privacy.section-4-5')}
                    </li>
                    <li className={classes.info}>
                        {t('privacy.section-4-6')}
                    </li>
                </ul>
                <p className={classes.info}>
                    {t('privacy.section-4-7')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-4-8')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-4-9')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-5-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-5-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-5-1')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-6-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-6-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-6-1')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-6-2')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-7-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-7-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-7-1')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-8-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-8-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-8-1')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-9-title')}
                </h2>
                <p className={classes.info}>
                    {t('privacy.section-9-1')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-10-title')}
                </h2>
                <p className={classes.info}>
                    {t('privacy.section-10-1')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-11-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-11-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-11-1')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-11-2')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-12-title')}
                </h2>
                <p className={classes.explination}>
                    {t('privacy.section-12-explination')}
                </p>
                <p className={classes.info}>
                    {t('privacy.section-12-1')}
                </p>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-13-title')}
                </h2>
                <p className={classes.info}>
                    {t('privacy.section-13-1')}
                </p>
                <address className={classes.info}>
                    {t('privacy.section-13-2')} <br />
                    {t('privacy.section-13-3')} <br />
                    {t('privacy.section-13-4')} <br />
                    {t('privacy.section-13-5')} <br />
                    {t('privacy.section-13-6')}
                </address>

                <h2 className={classes.sectionTitle}>
                    {t('privacy.section-14-title')}
                </h2>
                <p className={classes.info}>
                    {t('privacy.section-14-1')}
                </p>
            </GridItem>
        </GridContainer>

        <Footer />
    </>
  );
}

export default PrivacyPolicyPage;
