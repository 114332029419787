import { container, defaultFont, paragraph } from "assets/jss/material-kit-react.js";

const componentsStyle = {
  container,
  paragraph: {
    ...paragraph,

    "& > h1, h2, h3, h4": {
      ...defaultFont,
    }
  },
  absolute: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative"
  },
  subtitle: {
    ...defaultFont,
    fontSize: "25px",
    color: "#FFFFFF",
    width: "50%",
    maxWidth: "500px",
    margin: "10px 0 0",

    "@media (min-width: 960px)": {
      fontSize: "35px",
    },

    "@media (min-width: 1440px)": {
      width: "100%",
      maxWidth: "65vw",
      fontSize: "55px",
    }
  },
  headline: {
    ...defaultFont,
    fontSize: "25px",
    textAlign: "left",
  },
  parallaxContent: {
    height: "100%",
    alignItems: "center",
    display: "flex",
    paddingLeft: "0",
    paddingRight: "0",

    "@media (min-width: 960px)": {
      flexWrap: "wrap",
    }
  },
  parallaxInfoContainer: {
    width: "100%",

    "@media (min-width: 960px)": {
      marginTop: "50px",
    }
  },
  stallImage: {
    position: "absolute",
    height: "50vh",
    top: "0",
    left: "50%",
    zIndex: "-1",

    "@media (min-width: 576px)": {
      top: "15%",
    },
    "@media (min-width: 992px)": {
      height: "80vh",
    },
  },
  main: {
    ...container,
    background: "transparent",
    position: "relative",
    zIndex: "3",
    paddingLeft: "0px",
    paddingRight: "0px",

    "@media (min-width: 960px)": {
      paddingTop: "0px",
      paddingBottom: "0px",
    }
  },
  section:{
    ...container,
    background: "transparent",
    position: "relative",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  whiteBackground: {
    background: "#FFFFFF",
    overflow: "hidden",
  },
  noPadding: {
    padding: "0px",
  },
  noTopPadding: {
    paddingTop: "0px",

    "@media (min-width: 960px)": {
      paddingBottom: "0px",
      height: "100%",
    }
  },
  noBottomPadding: {
    paddingBottom: "0px",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },
  floatingAnnouncement: {
    display: "flex",
    alignItems: "center",
    width: "130px",
    height: "130px",
    position: "absolute",
    bottom: "-50px",
    right: "10%",
    background: "#000",
    borderRadius: "50%",

    "@media (min-width: 960px)": {
      width: "180px",
      height: "180px",
      bottom: "-85px",
      right: "0",
    } 
  },
  floatingAnnouncementText: {
    ...defaultFont,
    color: "#fff",
    fontSize: "10px",
    textAlign: "center",
    padding: "10px",
  },
  signupContainer: {
    width: "calc(50% - 20px)",
    marginLeft: "20px",

    "@media (min-width: 960px)": {
      width: "45%",
      marginLeft: "50px",
    }
  },
  signupButtonsContainer: {
    display: "flex",
  },
  signupParagraph: {
    ...defaultFont,
    fontSize: "12px",
    textAlign: "left",
    marginTop: "30px",

    "@media (min-width: 960px)": {
      fontSize: "20px",
    },

    "@media (min-width: 1440px)": {
      fontSize: "25px",
    }
  },
  signupConfirmation: {
    "@media (min-width: 960px)": {
      maxWidth: "50vw",
    },

    "@media (min-width: 1440px)": {
      maxWidth: "70%",
    }
  },
  diamondOneContainer: {
    position: "relative",
    left: "-250px",

    "@media (min-width: 960px)": {
      left: "-73%",
      top: "10%",
    }
  },
  diamondTwoContainer: {
    position: "relative",
    right: "-35%",
    width: "100%",
    overflow: "hidden",

    "@media (min-width: 960px)": {
      right: "-65%",
      top: "-2%",
      maxHeight: "560px",
    }
  },
  diamondImage: {
    width: "100%",
    minWidth: "450px",
    maxWidth: "1000px",
  },
  diamondsContainer: {
    position: "absolute",
    width: "100%",
    overflow: "hidden",
    right: "-35%",
    top: "-15%",
    zIndex: "-1",

    "@media (min-width: 960px)": {
      top: "-65%",
      right: "-80%",
    }
  },
  sectionOneContainer: {
    position: "relative",
    top: "-12%",
    width: "80%",
    marginLeft: "auto",

    "@media (min-width: 960px)": {
      position: "absolute",
      top: "30%",
      left: "30%",
      zIndex: "2",
    }
  },
  sectionOneHeading : {
    "@media (min-width: 960px)": {
      position: "absolute",
      top: "25%",
      left: "30%",
      zIndex: "2",
    }
  },
  sectionOneParagraph: {
    ...defaultFont,
    fontSize: "12px",
  },
  checkMarksContainer: {
    position: "relative",
    top: "-100px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "305px",

    "@media (min-width: 960px)": {
      position: "absolute",
      top: "37%",
      left: "40%",
      zIndex: "2",
    }
  },
  checkMarkContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  checkMark: {
    width: "24px",
    height: "35px",
  },
  checkMarkText: {
    ...defaultFont,
    fontSize: "12px",
  },
  imageAndMottoContainer: {
    "@media (min-width: 960px)": {
      display: "flex",
    }
  },
  mottoContainer: {
    "@media (min-width: 960px)": {
      display: "flex",
      flexDirection: "column",
      width: "60%",
      marginLeft: "15%",
    }
  },
  phoneImage: {
    width: "50%",

    "@media (min-width: 960px)": {
      width: "30%",
      maxWidth: "250px",
    }
  },
  signupMottoText: {
    textTransform: "capitalize",
  },
  mottoText: {
    ...defaultFont,
    fontSize: "25px",
    width: "50%",
    paddingTop: "25px",
    paddingLeft: "10px",

    "@media (min-width: 960px)": {
      width: "100%",
      marginTop: "15%",
    }
  },
  iconsContainer: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "10px",
    paddingRight: "10px",

    "@media (min-width: 960px)": {
      flexWrap: "nowrap",
      marginTop: "30px",
    }
  },
  iconContainer: {
    display: "flex",
    flexBasis: "50%",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "15px",
  },
  icon: {
    width: "40%",
  },
  iconText:{
    ...paragraph,
    paddingTop: "10px",
    textAlign: "center",
  },
  lastSection: {
    position: "relative",
    top: "-20%",
    marginBottom: "-130px",

    "@media (min-width: 960px)": {
      position: "absolute",
      top: "20%",
    }
  },
  decorationCircle: {
    position: "absolute",
    bottom: "10%",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "3px",

    "@media (min-width: 960px)": {
      top: "50%",
      width: "35px",
      height: "35px",
    }
  },
  decorationLineHeader: {
    position: "absolute",
    left: "12px",
    bottom: "0",
    height: "10%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",

    "@media (min-width: 960px)": {
      height: "calc(50% - 35px)",
      left: "14px",
    }
  },
  decorationLineVertical: {
    position: "absolute",
    left: "27px",
    top: "calc(25% + 17px)",
    width: "calc(25% - 25px)",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    zIndex: "2",
  },
  decorationCircleVertical: {
    position: "absolute",
    top: "25%",
    left: "25%",
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "3px",
  },
  decorationLineSectionOne: {
    position: "absolute",
    left: "27px",
    top: "-30px",
    height: "calc(100% + 30px)",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
  },
  decorationLineSectionTwo: {
    position: "absolute",
    left: "27px",
    top: "0",
    height: "calc(100% - 50px)",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    zIndex: "2",
  },
  decorationLineSectionThree: {
    position: "absolute",
    right: "27px",
    top: "-50px",
    height: "20%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    zIndex: "2",
  },
  decorationLineSectionFour: {
    position: "absolute",
    right: "27px",
    top: "calc(20% - 20px)",
    height: "100%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    zIndex: "2",
  },
  decorationLineSectionFive: {
    position: "absolute",
    right: "27px",
    top: "0",
    height: "110%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    zIndex: "2",
  },
  decorationLineHorizontalOne: {
    position: "absolute",
    bottom: "0px",
    left: "27px",
    width: "65%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    background: "#000000",
  },
  decorationLineHorizontalTwo: {
    position: "absolute",
    bottom: "50px",
    left: "27px",
    width: "calc(100% - 54px)",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    background: "#000000",
  },
  decorationLineHorizontalThree: {
    position: "absolute",
    top: "40%",
    right: "27px",
    width: "65%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "2px",
    zIndex: "2",
    background: "#000000",

    "@media (min-width: 960px)": {
      top: "0",
      width: "90%",
    }
  },
  decorationCircleHorizontal: {
    position: "absolute",
    bottom: "-12px",
    left: "calc(65% + 27px)",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "3px",
  },
  decorationCircleHorizontalTwo: {
    position: "absolute",
    top: "calc(20% - 50px)",
    right: "14px",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "3px",

    "@media (min-width: 960px)": {
      top: "calc(20% - 52px)",
      right: "12px",
      width: "35px",
      height: "35px",
    }
  },
  decorationCircleHorizontalThree: {
    position: "absolute",
    top: "calc(40% - 14px)",
    right: "calc(65% + 25px)",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    borderColor: "#000000",
    borderStyle: "solid",
    borderWidth: "3px",

    "@media (min-width: 960px)": {
      top: "-16px",
      right: "calc(90% + 26px)",
      width: "35px",
      height: "35px",
    }
  },
  signupButton: {
    width: "100%",

    "@media (min-width: 960px)": {
      width: "auto",
      minWidth: "150px",
    }
  },
  formButton: {
    "@media (min-width: 960px)": {
      width: "auto",
      minWidth: "180px",
      marginRight: "15px",
    }
  },
};

export default componentsStyle;
