import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Fade from "@material-ui/core/Fade";

// core components
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/components/headerStyle.js";

import logoImage from "assets/img/logo.png";

const useStyles = makeStyles(styles);

const Header = props => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const { color, changeColorOnScroll, leftLinks, fixed, absolute, isApplicant } = props;

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;

    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  const brandComponent = <Link to={"/"} className={classes.title}> <img src={logoImage} alt="Fairhub home" className={classes.logoImg} /> </Link>;

  return (
    <>
      <AppBar position="fixed" className={appBarClasses}>
        <Toolbar className={classes.container}>
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden smDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
              brandComponent
            )}
          </div>
          <Hidden smDown>
            <HeaderLinks closeMenu={() => mobileOpen && handleDrawerToggle()} isApplicant={isApplicant}/>
          </Hidden>
          <Hidden mdUp>
            <Button disableRipple={true} className={mobileOpen ? classes.menuCross: classes.menuHamburger} onClick={handleDrawerToggle}>
              <div className={classes.aniBefore}></div>
              <div className={classes.ani}></div>
              <div className={classes.aniAfter}></div>
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Fade in={mobileOpen}>
        <div className={classes.drawerPaper}>
          <div className={classes.appResponsive}>
            {leftLinks}
            <HeaderLinks closeMenu={() => mobileOpen && handleDrawerToggle()} isApplicant={isApplicant}/>
          </div>
        </div>
      </Fade>
    </>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default Header;