const parallaxStyle = {
  parallax: {
    height: "55vh",
    position: "relative",
    backgroundImage: 'url(hero-mobile.jpg)',
    backgroundPosition: "top center",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    zIndex: "20",
    maxHeight: "350px",

    "@media (min-width: 576px)": {
      height: "60vh",
      maxHeight: "none",
    },
    "@media (min-width: 992px)": {
      height: "85vh",
      backgroundImage: 'url(hero.jpg)',
    }
  },
  parallaxCompany: {
    overflow: "hidden",
    backgroundImage: 'url(company-background.jpg)',

    "@media (min-width: 992px)": {
      height: "85vh",
      backgroundImage: 'url(company-background.jpg)',
    }
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "380px"
  },
  image: {
    maxWidth: "100%",
    maxHeight:"100%",
    verticalAlign: "middle",
  }
};

export default parallaxStyle;
