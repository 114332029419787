import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
} from "assets/jss/material-kit-react.js";

const headerStyle = {
  appBar: {
    display: "flex",
    border: "0",
    padding: "0",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "absolute",
    zIndex: "1400"
  },
  absolute: {
    position: "absolute",
    zIndex: "1400"
  },
  fixed: {
    position: "fixed",
    zIndex: "1400"
  },
  container: {
    ...container,
    paddingLeft: "0px",
    paddingRight: "0px",
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    paddingTop: "10px",
    paddingBottom: "10px",

    "@media (min-width: 576px)": {
      maxWidth: "none",
    },
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "40px",
    borderRadius: "3px",
    textTransform: "none",
    color: "#000000",
    padding: "8px 16px",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "#000000",
      background: "transparent"
    }
  },
  logoImg: {
    width: "150px"
  },
  appResponsive: {
    margin: "20px 10px"
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    boxShadow: "none"
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)"
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)"
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)"
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
  },
  rose: {
    backgroundColor: roseColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "0px",
    color: "#FFFFFF"
  },
  dark: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
  },
  white: {
    border: "0",
    padding: "0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  drawerPaper: {
    backgroundColor: primaryColor,
    border: "none",
    bottom: "0",
    zIndex: "1200",
    position: "fixed",
    width: "100%",
    height: "100vh",
    paddingTop: "25%",
  },
  iconButton: {
    color: "#000000",
    "& svg" : {
      width: "28px",
      height: "28px"
    }
  },
  ani: {
    top: "13px",
    left: "20px",
    content: '',
    position: "absolute",
    height: "3px",
    width: "28px",
    background: "rgb(33, 33, 33)",
    transition: ".3s",
  },
  aniBefore: {
    content: '',
    position: "absolute",
    height: "3px",
    width: "28px",
    background: "rgb(33, 33, 33)",
    transition: ".3s",
    top: "5px",
    left: "20px",
  },
  aniAfter: {
    content: '',
    position: "absolute",
    height: "3px",
    width: "28px",
    background: "rgb(33, 33, 33)",
    transition: ".3s",
    top: "21px",
    left: "20px",
  },
  animated: {
    background: "transparent",
  },
  menuHamburger: {
    position: "relative",
    width: "28px",
    height: "35px",
    cursor: "pointer",
    transition: ".3s",
    display: "inline-block",
    padding: "0px",
    "&:hover": {
      background: "transparent",
    },
  },
  menuCross: {
    position: "relative",
    width: "28px",
    height: "35px",
    cursor: "pointer",
    transition: ".3s",
    display: "inline-block",
    padding: "0px",
    "&:hover": {
      background: "transparent",
    },
    "& $ani": {
      width: "0",
    },
    "& $aniBefore": {
      top: "13px",
      transform: "rotate(45deg)",
    },
    "& $aniAfter": {
      top: "13px",
      transform: "rotate(-45deg)",
    }
  }
};

export default headerStyle;
