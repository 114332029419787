import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import EditableInput from "components/EditableInput/EditableInput.js";

import profile from "assets/img/faces/christian.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment);

const MyCalendar = props => (
  <div>
    <Calendar
      localizer={localizer}
      events={[{
        id: 0,
        title: 'All Day Event very long title',
        allDay: true,
        start: new Date(2020, 1, 8),
        end: new Date(2020, 1, 9),
      }]}
      startAccessor="start"
      endAccessor="end"
    />
  </div>
)

const useStyles = makeStyles(styles);

const USER_INFO = gql`
  query {
    getApplicantUser(userName:"gstoian") {
      firstName
      lastName
      age
      educationLevel
      languages {
        language
        langagueLevel
      }
      location {
        city
        country
      }
      experience {
        description
      }
      workingRadio
      wage
      physicalAttributes {
        height
        hair {
          color
        }
        eyeColor
        clothing {
          shirt
          shoes
          pants
        }
        hasTattoo
        hasPiercing
      }
    }
  }
`;

const UPDATE_INFO = gql`
  mutation updateUserData ($firstName:String, $lastName: String) {
    updateUserData(userName:"gstoian", firstName:$firstName, lastName: $lastName) {
      userName
      firstName
      lastName
    }
  }
`;

const getLanguages = languages => {
  let output = '';

  languages.forEach(lang => {
    output += `${lang.language} - ${lang.langagueLevel}; `;
  })

  return output;
};

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const { loading, data } = useQuery(USER_INFO);

  const [updateUserData] = useMutation(
    UPDATE_INFO,
    {
      onCompleted({ updateUserData }) {
        console.log('Update done', updateUserData);
      }
    }
  );

  return (
    <div>
      <Header
        color="transparent"
        brand="fairhub"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      { loading ? 
        <p>Loading</p> :
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.profile}>
                    <div className={classes.profileImgContainer}>
                      <img src={profile} alt="Profile Picture" className={classes.imgFluid} />
                    </div>
                    <div className={classes.name}>
                     <h3 className={classes.title}>My Profile</h3>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} className={classes.navWrapper}>
                  <div className={classes.infoSection}>
                      <EditableInput
                        id="firstName"
                        lableText=" First Name"
                        handleUpdate={updateUserData}
                        value={data.getApplicantUser.firstName} />
                        <EditableInput
                          id="lastName"
                          lableText="Last Name"
                          handleUpdate={updateUserData}
                          value={data.getApplicantUser.lastName} />
                      <EditableInput id="age" lableText="Age" type="number" value={data.getApplicantUser.age} handleUpdate={updateUserData}/>
                      <EditableInput id="city" lableText="Place of residence" value={data.getApplicantUser.location.city} />
                      <EditableInput id="educationLevel" lableText="Education" value={data.getApplicantUser.educationLevel} />
                      <EditableInput id="languages" lableText="Languages" value={getLanguages(data.getApplicantUser.languages)} />
                      <EditableInput id="workable-cities" lableText="Workable cities" value={data.getApplicantUser.workingRadio} />
                      <EditableInput id="experience" lableText="Experience" value={data.getApplicantUser.experience.description} />
                      <EditableInput id="wage" lableText="Wage" value={data.getApplicantUser.wage} />
                      
                      <EditableInput id="hair-color" lableText="Hair color" value={data.getApplicantUser.physicalAttributes.hair.color} />
                      <EditableInput id="eye-color" lableText="Eye color" value={data.getApplicantUser.physicalAttributes.eyeColor} />
                      <EditableInput id="height" lableText="Height" value={`${data.getApplicantUser.physicalAttributes.height} cm`} />
                      <EditableInput id="clothing-size" lableText="Clothing size" value={data.getApplicantUser.physicalAttributes.clothing.shirt} />
                      <EditableInput 
                        id="tattoo-piercing"
                        lableText="Visibale piercings or tattoos" 
                        value={
                          data.getApplicantUser.physicalAttributes.hasTattoo || data.getApplicantUser.physicalAttributes.hasPiercing ? 'Yes' : 'No'
                        }
                      />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <MyCalendar />
              </GridContainer>
            </div>
          </div>
        </div>
      }
      <Footer />
    </div>
  );
}
